import { useContext, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { addComplaints } from '../../utils/apiCall/complaints';
import {
  ComplaintData,
  ComplaintFormDataProps,
  Predictions,
} from '../../utils/types/complaint';
import { CatalogDataContext } from '../../contexts/CatalogContextProvider';
import { batchUploadPageBreadCrumbList } from '../../utils/constant';
import Button from '../../components/elements/Button';
import UploadFile from '../../components/batchUpload/UploadFile';
import PageHeader from '../../components/pageHeader/PageHeader';
import { dataGenerator } from '../../utils/helperFunctions';
import { useMutation } from '@tanstack/react-query';
import { ExportButton } from '../../components/exportButton';
const BatchUpload = () => {
  const { catalogData, setCatalogData, setIsBatchFileUpload } =
    useContext(CatalogDataContext);

  const [csvData, setCSVData] = useState<any>([]);

  const navigate = useNavigate();

  const transformComplaints: ComplaintFormDataProps[] = useMemo(
    () =>
      csvData.map((complaint: ComplaintData, index: number) => {
        return {
          id: Date.now() + index,
          modelNo: complaint?.sku || complaint?.modelnumber,
          productName: complaint?.productname || '',
          reportedDate: complaint?.date || complaint?.reporteddate,
          region: complaint?.country || complaint['country/region'],
          eventDescription:
            complaint?.description || complaint?.eventdescription || '',
        };
      }),
    [csvData]
  );

  const uploadComplaints = useMutation<
    any,
    Error,
    ComplaintFormDataProps[],
    unknown
  >({
    mutationFn: (complaints) => addComplaints(complaints),
    onSuccess: ({ data: response }) => {
      const predictions: Predictions[] = JSON.parse(
        response.data?.AddComplaint?.output as string
      );
      const updateCatalogData = dataGenerator(transformComplaints, predictions);
      setCatalogData([...catalogData, ...updateCatalogData]);
      setIsBatchFileUpload(true);
      navigate('/');
    },
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      toast.error(
        'Upload unsuccessful. Please use the correct template format, Event Description field must not be blank, and file format must be a csv or xlsx format.'
      );
    },
  });

  return (
    <>
      <PageHeader
        title="Batch Upload"
        breadCrumbList={batchUploadPageBreadCrumbList}
      >
        <div className="flex items-center justify-center space-x-4">
          <div>
            <ExportButton buttonType="template" />
          </div>

          <div>
            <ExportButton buttonType="definitions" />
          </div>
          <div>
            <ExportButton buttonType="sample" />
          </div>
          <Button
            buttonLabel={uploadComplaints.isPending ? 'Uploading...' : 'Upload'}
            additionalClasses={
              csvData.length === 0
                ? 'text-gray-900 bg-gray-300 opacity-50 cursor-not-allowed border-primaryBorder'
                : 'text-white bg-primaryColor font-semibold'
            }
            onClick={() => uploadComplaints.mutate(transformComplaints)}
            additionalProps={{ disabled: csvData.length === 0 }}
          />
          <Link
            to="/"
            className="inline-flex justify-center text-sm md:text-base py-2 px-4 rounded-[4px] border font-semibold text-primaryColor border-primaryBorder"
          >
            Cancel
          </Link>
        </div>
      </PageHeader>
      <div className="px-7">
        <UploadFile setCSVData={setCSVData} csvData={csvData} />
      </div>
    </>
  );
};

export default BatchUpload;
