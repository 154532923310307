import { ManualEntryFormFooterProps } from '../../utils/types/ManualEntryForm';
import Button from '../elements/Button';

const ManualEntryFooter = ({
  addForm,
  complaintLimitReached,
}: ManualEntryFormFooterProps) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 px-10 py-4 border-t bg-white">
      <div className="flex items-center justify-between">
        <Button
          Disabled={complaintLimitReached}
          buttonLabel="Add a complaint"
          additionalClasses={
            'ml-20 text-primaryColor border-primaryBorder' +
            (complaintLimitReached ? ' cursor-not-allowed opacity-1' : '')
          }
          onClick={addForm}
        />

        <p className="text-primaryColor text-sm">
          Fields with asterisk are required
        </p>
      </div>
    </div>
  );
};

export default ManualEntryFooter;
