import { secrets } from '../../secrets';
import axios from 'axios';

export const addInterestedUser = async (id: string) => {
  const data = {
    query: `mutation AddIsInterested($id: String!) {
      AddIsInterested(id: $id) {
        code
        email
        message
        success
      }
    }`,
    variables: {
      id,
    },
  };
  const url = `${secrets.apiUrl}`;
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.post(url, JSON.stringify(data), config);
  return response;
};

export const addUserInfo = async (userInfo: any) => {
  const { acceptedTerms, firstName, lastName, email, company, role } = userInfo;
  const data = {
    query: `mutation AddUserInfo($addUserInput: AddUserInfoInput) {
      AddUserInfo(addUserInput: $addUserInput) {
        code
        email
        message
        success
        id
      }
    }`,
    variables: {
      addUserInput: {
        acceptTerms: acceptedTerms,
        company,
        email,
        firstName,
        lastName,
        role,
      },
    },
  };
  const url = `${secrets.apiUrl}`;
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.post(url, JSON.stringify(data), config);
  return response;
};
