import { FileUploader } from 'react-drag-drop-files';
import { DragDropInputProps } from '../../utils/types/elements';

const DragDropInput = ({
  content,
  handleFileUpload,
  fileTypes,
  fileName,
  setFileName,
  setError,
}: DragDropInputProps) => {
  return (
    <FileUploader
      multiple={false}
      handleChange={handleFileUpload}
      name="file"
      types={fileTypes}
      required={true}
      disabled={false}
      hoverTitle="Drag and Drop File"
      onTypeError={(err: any) => {
        setError(`${err}!. Please upload valid file (csv, xls).`);
        if (fileName) {
          setFileName('');
        }
      }}
    >
      {content}
    </FileUploader>
  );
};

export default DragDropInput;
