import axios from 'axios';
import { secrets } from '../../secrets';
import { ComplaintFormDataProps } from '../types/complaint';

// export const addComplaints = async (data: ComplaintFormDataProps[]) => {
//   const url = `${secrets.aIUrl}/run`;
//   const config = {
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   };

//   const response = await axios.post(url, { data }, config);
//   return response;
// };

export const addComplaints = async (complaints: ComplaintFormDataProps[]) => {
  const data = {
    query: `mutation AddComplaint($complaintInput: ComplaintInput) {
  AddComplaint(complaintInput: $complaintInput) {
    status
    output
    message
  }
}`,
    variables: {
      complaintInput: {
        data: complaints,
      },
    },
  };
  const url = `${secrets.apiUrl}`;
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.post(url, JSON.stringify(data), config);
  return response;
};
