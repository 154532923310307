import { memo, useEffect, useContext } from 'react';
import CatalogTable from '../../components/catalog/CatalogTable';
import { CatalogDataContext } from '../../contexts/CatalogContextProvider';

const Catalog = () => {
  const { setWelcomeWizard } = useContext(CatalogDataContext);

  useEffect(() => {
    const isModalShown = localStorage.getItem('isModalShown');
    if (!isModalShown) {
      setWelcomeWizard(true);
      localStorage.setItem('isModalShown', 'true');
    }
  }, []);
  return <CatalogTable />;
};

export default memo(Catalog);
