import { useState, useContext } from 'react';
import * as XLSX from 'xlsx';

import SVGRender from '../svgRender/SVGRender';
import Button from '../elements/Button';
import DragDropInput from './DragDropInput';
import { toast } from 'react-hot-toast';
import { secrets } from '../../secrets';
import { CatalogDataContext } from '../../contexts/CatalogContextProvider';

type UploadFileProps = {
  csvData: any;
  setCSVData: React.Dispatch<React.SetStateAction<any>>;
};

const UploadFile = ({ setCSVData, csvData }: UploadFileProps) => {
  const [error, setError] = useState('');
  const [fileName, setFileName] = useState('');

  const { catalogData } = useContext(CatalogDataContext);

  const fileTypes = ['csv', 'xlsx'];

  const handleFileUpload = (file: File) => {
    if (file) {
      setError('');
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = event?.target?.result;
        const workbook = XLSX.read(data, { type: 'binary', cellDates: true });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        const sanitizedData = parsedData.map((item: any) => {
          const sanitizedItem: { [key: string]: any } = {};
          for (const key in item) {
            if (Object.prototype.hasOwnProperty.call(item, key)) {
              const sanitizedKey = key
                .replace(/\s/g, '')
                .replace(/\.$/, '')
                .toLowerCase();

              sanitizedItem[sanitizedKey] = item[key];
            }
          }

          sanitizedItem.date =
            sanitizedItem?.date instanceof Date && sanitizedItem?.date
              ? sanitizedItem?.date.toISOString().substring(0, 10)
              : typeof sanitizedItem?.date === 'string'
              ? new Date(sanitizedItem.date).toString() !== 'Invalid Date'
                ? new Date(sanitizedItem.date).toISOString().substring(0, 10)
                : undefined
              : undefined;

          return sanitizedItem;
        });
        // TODO: Mov into context
        if (
          sanitizedData.length + catalogData.length >
          Number(secrets.complaintsLimit)
        ) {
          toast.error(
            `Maximum of ${secrets.complaintsLimit} complaints allowed per SmartComplaints demo version session.`
          );
          return;
        }

        setCSVData(sanitizedData as any);
        setFileName(file.name);
      };

      reader.readAsBinaryString(file);
    }
  };

  const bodyContent = (
    <div className="h-[calc(100vh-20rem)] lg:h-[calc(100vh-17rem)] flex items-center justify-center border cursor-pointer">
      <div className="flex items-center space-x-5">
        <SVGRender svgName="uploadIcon" />
        {error || fileName ? (
          <p
            className={`${
              fileName ? 'text-green-600' : 'text-red-500'
            } text-sm`}
          >
            {error || fileName}
          </p>
        ) : (
          <p className="text-primaryColor font-bold">
            Drag and Drop File (csv, xlsx)
          </p>
        )}
      </div>
    </div>
  );

  const headerContent = (
    <div className="flex items-center justify-between rounded-md border pl-4 cursor-pointer">
      <p className="text-gray-500">
        {fileName || 'Browse or drop a document...'}
      </p>
      <Button
        buttonType="secondary"
        buttonLabel={
          <div className="flex items-center">
            <SVGRender svgName="browseIcon" />
            <span className="ml-2">Browse</span>
          </div>
        }
        additionalClasses="!text-[#4B5563] !bg-[#E5E7EB] font-normal border-0 rounded-sm !font-normal"
      />
    </div>
  );

  const clearAll = () => {
    setCSVData([]);
    setFileName('');
    setError('');
  };

  return (
    <>
      <div className="flex items-center justify-between space-x-2 mb-4">
        <div className="flex-1">
          <DragDropInput
            content={headerContent}
            handleFileUpload={handleFileUpload}
            fileTypes={fileTypes}
            fileName={fileName}
            setFileName={setFileName}
            setError={setError}
          />
        </div>
        <div className="w-fit">
          <Button
            buttonType="secondary"
            buttonLabel="Clear All"
            additionalClasses={
              csvData.length === 0
                ? '!text-gray-900 bg-gray-300 cursor-not-allowed opacity-50'
                : '!text-white !bg-primaryColor'
            }
            onClick={clearAll}
            additionalProps={{ disabled: csvData.length === 0 }}
          />
        </div>
      </div>

      <DragDropInput
        content={bodyContent}
        handleFileUpload={handleFileUpload}
        fileTypes={fileTypes}
        fileName={fileName}
        setFileName={setFileName}
        setError={setError}
      />
    </>
  );
};

export default UploadFile;
