const HeaderCell = ({
  value,
  className = '',
  children,
}: {
  value: string;
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className={`${className} flex w-full justify-between items-center`}>
      {value}
      <div className="my-auto">{children}</div>
    </div>
  );
};

export default HeaderCell;
