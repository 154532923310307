import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useContext, useRef } from 'react';
import '../../components/complaint/Complaint.css';
import { Link } from 'react-router-dom';
import SVGRender from '../svgRender/SVGRender';
import { DropdownProps, OptionsProps } from '../../utils/types/elements';
import { CatalogDataContext } from '../../contexts/CatalogContextProvider';
import DropdownItemWizard from '../welcomeWizards/DropdownItemWizard';
import useWizard from '../../hooks/useWizard';

export default function Dropdown({ label, className, options }: DropdownProps) {
  const { currentWizard, welcomeWizard } = useContext(CatalogDataContext);

  const dropdownButton = useRef<HTMLButtonElement>(null);

  const { showDropdownItemsWizard } = useWizard();
  useEffect(() => {
    if (currentWizard === 3) {
      dropdownButton.current?.click();
    }
  }, [currentWizard]);

  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className={className} ref={dropdownButton}>
          <div className="flex justify-center items-center space-x-5">
            <p className="py-2 border-primaryBorder pl-3 pr-7">{label}</p>
            <SVGRender svgName="uploadDropDownIcon" className="!ml-3" />
          </div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none border-none z-30">
            {options?.map((item: OptionsProps, index: number) => (
              <Menu.Item key={index + 1}>
                {item.isLink ? (
                  showDropdownItemsWizard(item) ? (
                    <DropdownItemWizard
                      item={item}
                      index={index}
                      optionsLength={options.length}
                    />
                  ) : (
                    <Link
                      to={item.path}
                      className={` dropdown-item-border flex w-full items-center px-2 py-2 text-sm
                      ${
                        welcomeWizard
                          ? 'dropdown-inactive-item bg-black bg-opacity-50'
                          : 'hover:bg-gray-100'
                      }
                      ${index === 0 ? 'rounded-t-md border-t' : ''}
                      ${
                        index === options.length - 1
                          ? 'rounded-b-md border-b'
                          : ''
                      }`}
                    >
                      {item.label}
                    </Link>
                  )
                ) : (
                  <button className="text-[#1F2937] flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-gray-100">
                    {item.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
