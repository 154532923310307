import React, { useState, useContext, ChangeEvent, useMemo } from 'react';
import { CatalogDataContext } from '../../contexts/CatalogContextProvider';
import FormInput from '../elements/FormInput';
import { secrets } from '../../secrets';
import { useMutation } from '@tanstack/react-query';
import { addUserInfo } from '../../utils/apiCall/users';

const WelcomeWizardModal = () => {
  const { currentWizard, welcomeSlidesCount, handleNextWizard } =
    useContext(CatalogDataContext);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    role: '',
    acceptedTerms: false,
  });

  const saveUserInfo = useMutation<any, Error, void, unknown>({
    mutationFn: () => addUserInfo(formData),
    onSuccess: ({ data: resp }) => {
      const { email, id } = resp.data.AddUserInfo;
      localStorage.setItem('user', JSON.stringify({ email, id }));
      handleNextWizard();
    },
    onError: () => {
      alert('There was an error, please try again');
    },
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'acceptedTerms') {
      setFormData({
        ...formData,
        [name]: !formData.acceptedTerms,
      });
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    saveUserInfo.mutate();
  };

  const areFormValuesIncomplete = useMemo(() => {
    return (
      Object.values(formData).some((value) => value === '') ||
      !formData.acceptedTerms
    );
  }, [formData]);

  const defaultButtonClasses =
    'px-4 py-2 text-sm  rounded-md shadow-sm hover:bg-[#AC5C06] hover:text-white focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2';
  const buttonClasses = areFormValuesIncomplete
    ? defaultButtonClasses + ' cursor-not-allowed bg-[#F9FAFB] text-[#9CA3AF]'
    : defaultButtonClasses +
      ' bg-[#AC5C06] hover:bg-[#AC5C06] text-[white] hover:text-white';

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h1 className="text-xl font-semibold mb-3 text-[#844706]">
          Welcome to SmartComplaints™ Demo!
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="w-2/3">
            <div className="mb-3">
              <FormInput
                label="First Name*"
                name="firstName"
                type="text"
                additionalProps={{ placeholder: 'First Name' }}
                additionalClasses="rounded-md"
                onChange={handleChange}
                value={formData.firstName}
              />
            </div>
            <div className="mb-3">
              <FormInput
                label="Last Name*"
                name="lastName"
                type="text"
                additionalProps={{ placeholder: 'Last Name' }}
                additionalClasses="rounded-md"
                onChange={handleChange}
                value={formData.lastName}
              />
            </div>
            <div className="mb-3">
              <FormInput
                label="Email*"
                name="email"
                type="email"
                additionalProps={{ placeholder: 'Email' }}
                additionalClasses="rounded-md"
                onChange={handleChange}
                value={formData.email}
              />
            </div>
            <div className="mb-3">
              <FormInput
                label="Company*"
                name="company"
                type="text"
                additionalProps={{ placeholder: 'Company' }}
                additionalClasses="rounded-md"
                onChange={handleChange}
                value={formData.company}
              />
            </div>
            <div className="mb-3">
              <FormInput
                label="Role*"
                name="role"
                type="text"
                additionalProps={{ placeholder: 'Role' }}
                additionalClasses="rounded-md"
                onChange={handleChange}
                value={formData.role}
              />
            </div>
          </div>
          <div className="mb-3 flex">
            <input
              name="acceptedTerms"
              id="acceptedTerms"
              type="checkbox"
              onChange={handleChange}
              checked={formData.acceptedTerms}
            />
            <p className=" pl-2 text-base font-normal leading-6 tracking-tight text-left">
              By continuing, you agree to Global Exponential Technologies (GxT){' '}
              <a
                href={secrets.termsAndConditions as string}
                download="Terms and Conditions"
                target="_blank"
                rel="noreferrer"
                className="text-[#AC5C06] underline"
              >
                Terms and Conditions
              </a>
              .*
            </p>
          </div>
          <p className="text-xs text-[#AC5C06]">
            Fields with an asterisk are required
          </p>
          <div className="mt-3 flex justify-end space-x-4">
            <button
              disabled={areFormValuesIncomplete}
              type="submit"
              className={buttonClasses}
            >
              {currentWizard}/{welcomeSlidesCount} Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WelcomeWizardModal;
