import { NavLink } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '../../assets/icons/breadcrumb-icon.svg';

const Breadcrumbs = ({
  breadcrumb,
}: {
  breadcrumb: { label: string; link: string }[];
}) => {
  return (
    <nav className="flex mt-2" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-1">
        {breadcrumb.map((item, index) => (
          <li key={index} className="flex items-center">
            <NavLink
              to={item.link}
              className={({ isActive }) => {
                return `${
                  isActive
                    ? 'text-primaryColor font-semibold'
                    : 'text-primaryBorder'
                }`;
              }}
            >
              {item.label}
            </NavLink>

            {index !== breadcrumb.length - 1 && (
              <span className="ml-2 mr-1">
                <ArrowIcon />
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
