import { Link, NavLink } from 'react-router-dom';

import SVGRender from '../svgRender/SVGRender';
import logo from '../../assets/images/sidebar_logo.png';
import { useMemo } from 'react';

const SiderBar = () => {
  const isOpen = true;

  const navItems = useMemo(
    () => [
      {
        name: 'Catalog',
        svgName: 'catalogIcon',
        to: '/',
      },
    ],
    []
  );

  return (
    <aside
      id="logo-sidebar"
      className={`cursor-pointer fixed top-0 left-0 z-20 h-screen pt-4 px-2 transition-transform ${
        !isOpen ? '-translate-x-full' : ''
      } bg-white border-r border-none sm:translate-x-0`}
      aria-label="Sidebar"
    >
      <Link to="/" className="flex ml-1 mt-14 sm:mt-0">
        <img src={logo} className="w-16 h-auto" alt="logo" />
      </Link>
      <div className="h-[80%] sm:h-[90%] mt-10 overflow-hidden pb-4 overflow-y-auto bg-white">
        <ul className="my-auto space-y-8 font-medium">
          {navItems.map((item) => (
            <li key={item.name} className="group">
              <NavLink
                to={item.to}
                className="flex items-center justify-center border-l-4 border-primaryColor"
              >
                <SVGRender svgName={item.svgName} />
                <span className="bg-[#1a1b1b] text-sm shadow-lg text-white invisible transition-opacity duration-500 ease-in-out opacity-0 group-hover:opacity-100 group-hover:visible absolute px-4 py-1 left-[75px] rounded-md">
                  {item.name}
                </span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
};

export default SiderBar;
