import { Complaint } from '../../utils/types/complaint';
import AIData from './AIData';
import ViewComplaintField from './ViewComplaintField';
import './Complaint.css';
import ComplaintNavigation from './ComplaintNavigation';

const ViewComplaintData = ({
  complaint,
  nextComplaintId,
  prevComplaintId,
}: {
  complaint: Complaint | undefined;
  nextComplaintId?: number;
  prevComplaintId?: number;
}) => {
  if (!complaint)
    return (
      <p className="text-center text-gray-500 pt-20">No Complaint Found!</p>
    );

  const formattedDate = complaint.reportedDate
    ? new Date(complaint.reportedDate).toLocaleDateString()
    : '';
  return (
    <div className=" bg-white p-[40px] overflow-hidden">
      <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-9">
        <div className="md:col-span-3 sm:col-start-1">
          <ViewComplaintField
            label="Product Name"
            value={complaint.productName}
          />
        </div>
        <div className="md:col-span-2">
          <ViewComplaintField label="Model No" value={complaint.modelNo} />
        </div>
        <div className="md:col-span-2">
          <ViewComplaintField label="Reported Date" value={formattedDate} />
        </div>
        <div className="md:col-span-2">
          <ViewComplaintField
            label="Country / Region"
            value={complaint.region}
          />
        </div>
        <div className="col-span-full">
          <ViewComplaintField
            label="Event Description"
            value={complaint.eventDescription}
            className="h-48"
          />
        </div>
      </div>
      <AIData complaint={complaint} />
      <ComplaintNavigation
        prevComplaintId={prevComplaintId}
        nextComplaintId={nextComplaintId}
      />
    </div>
  );
};

export default ViewComplaintData;
