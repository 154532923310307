import { Disclosure, Transition } from '@headlessui/react';
import { FilterDisclosureProps } from '../../utils/types/slideOver';

import { ChevronUpIcon } from '@heroicons/react/20/solid';

const FilterDisclosure = ({
  filter,
  handleCheckboxChange,
  checkedFilterOptions,
}: FilterDisclosureProps) => {
  return (
    <div className="w-full border-b pb-2">
      <div className="mx-auto w-full max-w-md bg-white">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg px-2 py-2 text-left text-sm font-medium">
                <span className="font-semibold">{filter.title}</span>
                <ChevronUpIcon
                  className={`${
                    !open
                      ? 'transform ease-in-out duration-300'
                      : 'rotate-180 ease-in-out duration-300'
                  } h-5 w-5`}
                />
              </Disclosure.Button>
              <Transition
                show={!open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                  <div className="space-y-2">
                    {filter.options.map((option: string) => (
                      <div className="relative flex items-start" key={option}>
                        <div className="flex h-6 items-center">
                          <input
                            id={`filter-${option}`}
                            aria-describedby="comments-description"
                            name={`filter-${option}`}
                            type="checkbox"
                            value={option}
                            checked={checkedFilterOptions.includes(option)}
                            onChange={handleCheckboxChange}
                            className="h-4 w-4 rounded border-primaryBorder cursor-pointer"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor={`filter-${option}`}
                            className="text-gray-700 cursor-pointer"
                          >
                            {option}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

export default FilterDisclosure;
