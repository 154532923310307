import Breadcrumbs from '../breadCrumbs/BreadCrumbs';
import Title from '../elements/Title';

type PageHeaderProps = {
  breadCrumbList: { label: string; link: string }[];
  children?: React.ReactNode;
  title: string;
};

const PageHeader = ({
  breadCrumbList,
  children = null,
  title,
}: PageHeaderProps) => {
  return (
    <div className="p-7 mb-5">
      <div className="flex justify-between items-center">
        <Title text={title} />
        <div className="mt-2">{children}</div>
      </div>
      <Breadcrumbs breadcrumb={breadCrumbList} />
    </div>
  );
};

export default PageHeader;
