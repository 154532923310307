// TODO: Secrets are currently in GH - Move to Secrets Manager
export const secrets = {
  complaintsLimit: process.env.REACT_APP_COMPLAINTS_LIMIT || 100,
  manualComplaintsLimit: process.env.REACT_APP_MANUAL_COMPLAINTS_LIMIT,
  batchTemplateFileLink: process.env.REACT_APP_BATCH_TEMPLATE_LINK,
  terminologyLibraryFileLink: process.env.REACT_APP_TERMINOLOGY_LIBRARY_LINK,
  termsAndConditions: process.env.REACT_APP_TERMS_AND_CONDITIONS_LINK,
  apiUrl: process.env.REACT_APP_USER_FORM_API,
  sampleBatchDataFileLink: process.env.REACT_APP_SAMPLE_BATCH_DATA_LINK,
};
