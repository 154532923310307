import SearchField from '../elements/SearchField';
import SVGRender from '../svgRender/SVGRender';
import Dropdown from '../elements/DropDown';
import Button from '../elements/Button';
import { ActionButtonsProps } from '../../utils/types/elements';
import useWizard from '../../hooks/useWizard';

const ActionButtons = ({
  setIsFiltersSlideOpen,
  setSearchQuery,
  checkedFilterOptions,
}: ActionButtonsProps) => {
  const { renderUploadIntroWizard, showUploadCatalogWizard } = useWizard();
  const uploadOption = [
    { label: 'Manual Entry', isLink: true, path: '/manual-entry' },
    { label: 'Batch Upload', isLink: true, path: '/batch-upload' },
  ];

  const filterBtnText = (
    <>
      <span>Filter ({checkedFilterOptions.length})</span>
      <SVGRender svgName="filterIcon" />
    </>
  );

  const downloadBtnText = (
    <>
      <span>Download</span>
      <SVGRender svgName="lockIcon" />
    </>
  );

  return (
    <div className="flex justify-between flex-row space-x-1">
      <div className="flex-1 space-x-5">
        <SearchField
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search"
        />
      </div>
      <div className="space-x-2 flex justify-start 2xl:justify-end items-start">
        <Button
          buttonType="secondary"
          onClick={() => setIsFiltersSlideOpen(true)}
          buttonLabel={filterBtnText}
          additionalClasses="w-[148px] space-x-7 !text-primaryColor items-center border-primaryBorder"
        />
        <div className="relative">
          {showUploadCatalogWizard() && (
            <div className="fixed inset-0 bg-black bg-opacity-80 z-40"></div>
          )}
          <div
            className={`relative ${
              showUploadCatalogWizard() && 'z-50'
            } flex justify-center items-center `}
          >
            <Dropdown
              label="Upload"
              className="text-primaryColor bg-white font-semibold text-sm md:text-base px-3 rounded-[4px] border border-primaryBorder"
              options={uploadOption}
            />
          </div>
          {renderUploadIntroWizard()}
        </div>
        <Button
          buttonType="secondary"
          Disabled={true}
          buttonLabel={downloadBtnText}
          additionalClasses="w-[148px] space-x-5 bg-gray-200 text-gray-400 items-center !px-3 border-primaryBorder"
        />
      </div>
    </div>
  );
};

export default ActionButtons;
