import { Fragment, useMemo, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import SVGRender from '../svgRender/SVGRender';
import MenuItemsList from './MenuItemsList';
import { Link } from 'react-router-dom';
import PremiumFeatureModal from '../modal/PremiumFeatureModal';

const MenuList = ({ meta, row }: any) => {
  const [isVerificationModal, setIsVerificationModal] = useState(false);
  const id = row.original.id;
  const removeRow = () => meta?.removeRow(row.index);

  const menuItems = useMemo(
    () => [
      {
        title: (
          <Link
            className="w-full cursor-pointer flex items-center justify-between px-2 py-2 "
            to={`/${id}/view-complaint `}
          >
            View
          </Link>
        ),
        onClick: () => {},
        className: 'text-gray-500',
      },
      {
        title: (
          <div className="flex items-center justify-between px-2 py-2 ">
            <p>Verify</p>
            <SVGRender svgName="lockIcon" />
          </div>
        ),
        onClick: () => setIsVerificationModal(true),
        className: 'text-gray-500 cursor-pointer',
      },
      {
        title: (
          <div className="flex items-center justify-between px-2 py-2 ">
            Delete
          </div>
        ),
        onClick: () => removeRow(),
        className:
          'text-red-600 hover:bg-red-500 hover:text-white cursor-pointer',
      },
    ],
    [id]
  );

  return (
    <>
      {isVerificationModal && (
        <PremiumFeatureModal
          showPremiumFeatureModal={isVerificationModal}
          setShowPremiumFeatureModal={setIsVerificationModal}
        />
      )}
      <Menu as="div" className="relative inline-block text-left mt-1">
        <div>
          <Menu.Button data-testid="three-dots-icon" className="">
            <SVGRender svgName="threeDotsIcon" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-9 top-0 z-10 mt-1 w-28 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <MenuItemsList menuItems={menuItems} />
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default MenuList;
