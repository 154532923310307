import { useContext } from 'react';
import UploadWizard from '../components/welcomeWizards/UploadWizard';
import WelcomeWizardModal from '../components/welcomeWizards/WelcomeWizardModal';
import { CatalogDataContext } from '../contexts/CatalogContextProvider';
import { OptionsProps } from '../utils/types/elements';
import { WizardData } from '../utils/types/complaint';

const useWizard = () => {
  const { currentWizard, welcomeWizard } = useContext(CatalogDataContext);

  const wizardData: WizardData = {
    2: {
      title: 'Welcome!',
      description: (
        <span>
          Here&apos;s a quick tutorial: There are two ways you can upload
          complaints. <br />
          <br />
          <b>
            Please note: Data in the demo version will not be retained after
            session ends.
          </b>
        </span>
      ),
    },
    3: {
      title: 'Manual Entry',
      description: <>Add up to 10 complaints manually using our form.</>,
    },
    4: {
      title: 'Batch Upload',
      description: (
        <>Add up to 100 complaints using the SmartComplaints™ Template.</>
      ),
    },
    5: {
      title: 'Batch Template',
      description: <>Upload up to 100 complaints using our template.</>,
    },
  };

  const showBatchTemplateWizard = () => {
    return welcomeWizard && currentWizard === 5;
  };

  const showUploadCatalogWizard = () => {
    return (
      welcomeWizard &&
      (currentWizard === 2 || currentWizard === 3 || currentWizard === 4)
    );
  };

  const showDropdownItemsWizard = (item: OptionsProps) =>
    welcomeWizard &&
    ((currentWizard === 3 && item.label === 'Manual Entry') ||
      (currentWizard === 4 && item.label === 'Batch Upload'));

  const renderBatchTemplateWizard = () =>
    showBatchTemplateWizard() && (
      <UploadWizard
        title="Batch Template"
        description="Upload up to 100 complaints using our template."
      />
    );

  const renderUserFormWizard = () => {
    return welcomeWizard && currentWizard === 1 && <WelcomeWizardModal />;
  };

  const renderCatalogUploadWizard = () => (
    <UploadWizard
      title={wizardData[currentWizard].title}
      description={wizardData[currentWizard].description}
    />
  );

  const renderUploadIntroWizard = () => {
    return welcomeWizard && currentWizard === 2 && renderCatalogUploadWizard();
  };

  return {
    renderBatchTemplateWizard,
    renderUserFormWizard,
    showBatchTemplateWizard,
    showDropdownItemsWizard,
    renderUploadIntroWizard,
    showUploadCatalogWizard,
    renderCatalogUploadWizard,
    wizardData,
  };
};

export default useWizard;
