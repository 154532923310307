import ReactPaginate from 'react-paginate';
import SVGRender from '../svgRender/SVGRender';
import './Pagination.css';
import { useContext, useEffect } from 'react';
import { CatalogDataContext } from '../../contexts/CatalogContextProvider';

const Pagination = ({ table }: any) => {
  const { pageSize, setPageSize } = useContext(CatalogDataContext);

  const recordsPerPage = [10, 15, 30, 50, 100];
  const handlePageClick = (event: any) => {
    table.setPageIndex(event.selected);
  };

  const lastPageHandler = () => {
    table.lastPage();
    const totalNodes = document.querySelector('.paginated-box') as HTMLElement;
    const lastElement = totalNodes?.children[totalNodes.children.length - 2]
      .children[0] as HTMLElement;

    if (lastElement) {
      lastElement.click();
    }
  };
  const firstPageHandler = () => {
    table.firstPage();
    const firstElement = document.querySelector('.paginated-box')?.children[1]
      .children[0] as HTMLElement;

    if (firstElement) {
      firstElement.click();
    }
  };
  useEffect(() => {
    table.setPageSize(pageSize);
  }, []);

  return (
    <div className=" justify-between px-4 py-1 border">
      <div className="flex items-center w-full">
        <SVGRender
          svgName="doubleArrowLeft"
          className="cursor-pointer"
          onClick={firstPageHandler}
        />
        <ReactPaginate
          className="paginated-box"
          breakLabel="..."
          nextLabel={
            <button
              disabled={!table.getCanNextPage()}
              className={
                (!table.getCanNextPage() ? 'cursor-not-allowed' : '') +
                ' !px-1 border-none mt-2'
              }
            >
              <SVGRender
                svgName="arrowRight"
                onClick={() => table.nextPage()}
              />
            </button>
          }
          previousLabel={
            <button
              className={
                (!table.getCanPreviousPage() ? 'cursor-not-allowed' : '') +
                ' !px-1 border-none mt-2'
              }
              disabled={!table.getCanPreviousPage() ? true : false}
            >
              <SVGRender
                svgName="arrowLeft"
                onClick={() => table.previousPage()}
              />
            </button>
          }
          pageRangeDisplayed={3}
          pageCount={!table.getPageCount() ? 1 : table.getPageCount()}
          marginPagesDisplayed={1}
          activeClassName="ative-page"
          disabledClassName="disable-page"
          pageClassName="pages-count"
          onPageChange={handlePageClick}
        />
        <SVGRender
          svgName="doubleArrowRight"
          className="cursor-pointer"
          onClick={lastPageHandler}
        />

        <div className="flex justify-end flex-grow space-x-2 pl-5 items-center">
          <select
            value={pageSize}
            onChange={(e) => {
              const newSize = Number(e.target.value);
              setPageSize(newSize);
              table.setPageSize(newSize);
            }}
            className="bg-white border border-primaryBorder text-sm  w-[3.7rem] px-1.5 py-2 rounded-md text-primaryColor focus:outline-none"
          >
            {recordsPerPage.map((recordSize) => (
              <option key={recordSize} value={recordSize}>
                {recordSize}
              </option>
            ))}
          </select>
          <p className="text-sm mr-1 text-[#303D53] w-34">events per page</p>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
