import React, { useMemo, useState } from 'react';
import SVGRender from '../svgRender/SVGRender';

// NOTE: This component in in dire need of a refactor. It is not very flexible and has a lot of hardcoded values.
interface TooltipProps {
  text: string;
  children: React.ReactNode;
  verticalPercOffset?: number;
  horizontalPercOffset?: number;
  position?: 'top' | 'bottom' | 'left' | 'right';
}

const Tooltip: React.FC<TooltipProps> = ({
  text,
  children,
  verticalPercOffset = 300,
  horizontalPercOffset = 10,
  position = 'top',
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const setPosition = () => {
    switch (position) {
      case 'top':
        return {
          top: `-${verticalPercOffset}%`,
          left: `${horizontalPercOffset}`,
        };
      case 'bottom':
        return { bottom: `-${verticalPercOffset}%`, left: '20%' };
      case 'left':
        return {
          left: `${horizontalPercOffset}%`,
          top: `${verticalPercOffset}%`,
        };
      case 'right':
        return {
          right: `${horizontalPercOffset}%`,
          top: `${verticalPercOffset}%`,
        };
      default:
        return {
          top: `-${verticalPercOffset}%`,
          left: `${horizontalPercOffset}%`,
        };
    }
  };

  const arrowPositionClass = useMemo(() => {
    const defaultClass = 'absolute z-[9999] mt-[-2px]';
    switch (position) {
      case 'top':
        return defaultClass;
      case 'bottom':
        return defaultClass;
      case 'left':
        return defaultClass + ' left-[84px]';
      case 'right':
        return defaultClass;
      default:
        return defaultClass;
    }
  }, [position]);
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: 'relative', display: 'inline-block' }}
    >
      {children}
      {showTooltip && (
        <div
          style={{
            ...setPosition(),
            zIndex: 9997,
            position: 'absolute',
          }}
        >
          {position === 'bottom' && (
            <SVGRender
              svgName="ArrowDownIcon"
              className="absolute z-[9999] rotate-180 mt-[-6px]"
            />
          )}
          <div
            style={{
              transform: 'translateX(-50%)',
              backgroundColor: 'white',
              color: '#4B5563',
              fontSize: '16px',
              padding: '0.5rem',
              border: '1px solid #9CA3AF',
              borderRadius: '4px',
              opacity: 2,
              zIndex: 9998,
              width: 'max-content',
            }}
          >
            {text}
          </div>
          {position !== 'bottom' && (
            <SVGRender svgName="ArrowDownIcon" className={arrowPositionClass} />
          )}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
