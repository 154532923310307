import SVGRender from '../svgRender/SVGRender';
import Tooltip from '../tooltip';
export type AIDataFieldProps = {
  additionalClasses?: string;
  value: string;
  code: string;
};
const AIDataField = ({
  additionalClasses = '',
  value = '',
  code = '',
}: AIDataFieldProps) => {
  return (
    <div className="flex space-x-2">
      <div className="relative w-[20%]">
        <div
          className={`my-2 py-2 pl-1 block min-h-10 w-full text-sm text-gray-600 border border-primaryBorder rounded-lg ${additionalClasses}`}
        >
          {code}
        </div>
        <div className="absolute right-2 top-5">
          <Tooltip text="Auto-generated Classification">
            <SVGRender svgName="aIicon" />
          </Tooltip>
        </div>
      </div>
      <div className="relative w-[80%]">
        <div
          className={`my-2 py-2 pl-1 pr-4 block min-h-10 w-full text-sm text-gray-600 border border-primaryBorder rounded-lg ${additionalClasses}`}
        >
          {value}
        </div>
        <div className="absolute right-2 top-5">
          <span className="group">
            <Tooltip text="Auto-generated Classification">
              <SVGRender svgName="aIicon" />
            </Tooltip>
          </span>
        </div>
      </div>
    </div>
  );
};

export default AIDataField;
