import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { ReactRoutes } from './utils/types/routes';
import Layout from './layout/Layout';
import Catalog from './pages/catalog/Catalog';
import ManualEntry from './pages/manualEntry/ManualEntry';
import ViewComplaint from './pages/viewComplaint/ViewComplaint';
import BatchUpload from './pages/batchUpload/BatchUpload';
import NoPage from './pages/noPage/NoPage';
import CatalogContextProvider from './contexts/CatalogContextProvider';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
const AppRouter = () => {
  const layoutRoutes: ReactRoutes = [
    {
      id: 'l-0',
      component: ManualEntry,
      path: '/manual-entry',
    },
    {
      id: 'l-1',
      component: ViewComplaint,
      path: '/:id/view-complaint',
    },
    {
      id: 'l-3',
      component: BatchUpload,
      path: '/batch-upload',
    },
    {
      id: 'l-4',
      component: NoPage,
      path: '*',
    },
  ];

  const queryClient = new QueryClient();

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <QueryClientProvider client={queryClient}>
        <CatalogContextProvider>
          <div className="h-screen w-screen bg-neutral">
            <Router>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={<Catalog />} />
                  {layoutRoutes.map((route) => (
                    <Route
                      path={route.path}
                      element={<route.component />}
                      key={route.id}
                    />
                  ))}
                </Route>
              </Routes>
            </Router>
          </div>
        </CatalogContextProvider>
      </QueryClientProvider>
    </>
  );
};

export default AppRouter;
