import React, { HTMLProps, MouseEvent, useMemo } from 'react';

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  buttonLabel: string | JSX.Element;
  additionalClasses?: string;
  type?: 'button' | 'submit' | 'reset';
  additionalProps?: any;
  Disabled?: boolean;
  buttonType?: 'primary' | 'secondary';
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  buttonLabel,
  additionalClasses,
  additionalProps,
  type = 'button',
  Disabled = false,
  buttonType = 'primary',
}) => {
  const buttonStyling = useMemo(() => {
    switch (buttonType) {
      case 'primary':
        return 'bg-primaryColor text-white border-primaryColor';
      case 'secondary':
        return 'bg-white text-primaryColor border-grey';
      default:
        return 'bg-primaryColor text-white border-primaryColor';
    }
  }, [buttonType]);

  let className = `inline-flex justify-center text-sm md:text-base py-2 px-4 rounded-[4px] border font-semibold ${buttonStyling} ${additionalClasses}`;

  if (Disabled)
    className +=
      ' cursor-not-allowed !bg-[#E5E7EB] !text-[#9CA3AF] !border-[#D1D5DB]';

  return (
    <button
      disabled={Disabled}
      {...additionalProps}
      type={type}
      className={className}
      onClick={onClick}
    >
      {buttonLabel}
    </button>
  );
};

export default Button;
