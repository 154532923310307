import { Link } from 'react-router-dom';
import { OptionsProps } from '../../utils/types/elements';
import useWizard from '../../hooks/useWizard';

const DropdownItemWizard = ({
  item,
  optionsLength,
  index,
}: {
  item: OptionsProps;
  index: number;
  optionsLength: number;
}) => {
  const { renderCatalogUploadWizard } = useWizard();

  return (
    <div className="relative ">
      <div className="relative flex justify-center items-center z-50">
        <Link
          to={item.path}
          className={`text-[#1F2937] border-r border-l dropdown-item-border flex w-full items-center px-2 py-2 text-sm hover:bg-gray-100
      ${index === 0 ? 'rounded-t-md border-t' : ''}
      ${index === optionsLength - 1 ? 'rounded-b-md border-b' : ''}`}
        >
          {item.label}
        </Link>
      </div>
      {renderCatalogUploadWizard()}
    </div>
  );
};

export default DropdownItemWizard;
