import { FormField } from '../../utils/types/elements';

const FormInput = ({
  label,
  name,
  type,
  additionalProps,
  additionalClasses = '',
  labelClasses = '',
  fildType = 'input',
  disabled = false,
  value = '',
  onChange,
}: FormField) => (
  <>
    <label
      htmlFor={name}
      className={`${labelClasses} text-sm text-primaryColor font-semibold tracking-wide`}
    >
      {label}
    </label>
    {fildType === 'textarea' ? (
      <textarea
        {...additionalProps}
        name={name}
        id={name}
        value={value}
        className={`my-2 block w-full text-base p-2 border border-primaryBorder rounded-lg focus:outline-none focus:border-indigo-500 ${additionalClasses}`}
        disabled={disabled}
        onChange={onChange}
      />
    ) : (
      <input
        {...additionalProps}
        type={type}
        name={name}
        id={name}
        value={value}
        className={`my-2 block w-full text-base p-2 border border-primaryBorder rounded-lg focus:outline-none focus:border-indigo-500 ${additionalClasses}`}
        disabled={disabled}
        onChange={onChange}
      />
    )}
  </>
);

export default FormInput;
