/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { flexRender } from '@tanstack/react-table';
import SVGRender from '../svgRender/SVGRender';
import MenuList from '../Menu/Menu';
import NoData from './Nodata';
import './table.css';

const TableComponent = ({ table }: any) => {
  const meta = table.options.meta;
  const headersLength = table.getHeaderGroups().length
    ? Number(table.getHeaderGroups()[0].headers.length) + 1
    : 0;
  const tableDataLength = Object.keys(
    table.getRowModel().rowsById as number
  ).length;

  const getSortIcon = (column: any) => {
    const sort = column.getIsSorted();
    switch (sort) {
      case 'asc':
        return (
          <SVGRender
            svgName="sortUp"
            className="cursor-pointer"
            onClick={column.getToggleSortingHandler()}
          />
        );
      case 'desc':
        return (
          <SVGRender
            svgName="sortDown"
            className="cursor-pointer"
            onClick={column.getToggleSortingHandler()}
          />
        );
      default:
        return (
          <SVGRender
            svgName="sortIcon"
            className="cursor-pointer"
            onClick={column.getToggleSortingHandler()}
          />
        );
    }
  };

  return (
    <table
      className="min-w-full divide-y divide-gray-300 border"
      {...{
        style: {
          width: table.getCenterTotalSize(),
        },
      }}
    >
      <thead className="sticky top-0 z-20 ">
        {table.getHeaderGroups().map((headerGroup: any) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header: any) => (
              // eslint-disable-next-line react/jsx-key
              <th
                {...{
                  key: header.id,
                  colSpan: header.colSpan,
                  style: {
                    width: header.getSize(),
                  },
                }}
                className={`p-2 h-[40px] text-left text-sm font-semibold text-white relative ${
                  header.column.id === 'premiumFeature'
                    ? 'bg-[#E5E7EB]'
                    : 'bg-secondaryColor'
                }`}
              >
                <div className="flex justify-between h-full items-center grow">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  <div>
                    {header.column.columnDef.enableSorting &&
                      header.column.getCanSort() &&
                      getSortIcon(header.column)}
                  </div>
                </div>
                <div
                  {...{
                    onDoubleClick: () => header.column.resetSize(),
                    onMouseDown: header.getResizeHandler(),
                    onTouchStart: header.getResizeHandler(),
                    className: `resizer ${
                      table.options.columnResizeDirection
                    } ${header.column.getIsResizing() ? 'isResizing' : ''}`,
                    style: {
                      transform: header.column.getIsResizing()
                        ? `translateX(${
                            1 *
                            (table.getState().columnSizingInfo.deltaOffset ?? 0)
                          }px)`
                        : '',
                    },
                  }}
                />
              </th>
            ))}
            <th
              scope="col"
              className="!max-w-[70px] w-[70px] !min-w-20 px-4 py-3.5 text-left text-sm font-semibold text-white bg-secondaryColor"
            >
              <div className="w-full flex justify-between">Actions</div>
            </th>
          </tr>
        ))}
      </thead>
      <tbody className=" divide-gray-200 bg-white">
        {tableDataLength ? (
          table.getRowModel().rows.map((row: any, index: number) => (
            <tr
              key={row.id}
              className={`divide-x h-4 divide-gray-200 ${
                index % 2 === 0 ? '' : 'bg-gray-100'
              }`}
            >
              {row.getVisibleCells().map((cell: any) => {
                const rowSpan = cell.column.columnDef.rowSpan;
                const renderCell = (
                  <td
                    className="text-sm text-gray-500"
                    {...{
                      key: cell.id,
                      style: {
                        width: cell.column.getSize(),
                      },
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );

                if (!rowSpan) {
                  return renderCell;
                }

                if (index === 0) {
                  return (
                    <td
                      key={cell.id}
                      {...{
                        className: 'p-4 text-sm text-gray-500',
                        rowSpan,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                }

                return null;
              })}

              <td className="max-w-[80px] py-4 text-sm text-gray-500">
                <div className="border py-0.5 px-3 w-fit mx-auto rounded-md bg-white">
                  <MenuList meta={meta} row={row} />
                </div>
              </td>
            </tr>
          ))
        ) : (
          <NoData tableDataLength={headersLength} />
        )}
      </tbody>
    </table>
  );
};

export default TableComponent;
