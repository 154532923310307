const ViewComplaintField = ({
  label,
  value,
  className,
  labelClasses,
}: {
  label: string;
  value: string | undefined | Date;
  className?: string;
  labelClasses?: string;
}) => {
  return (
    <>
      <p
        className={`text-sm text-primaryColor font-semibold tracking-wide ${
          labelClasses ? labelClasses : ''
        }`}
      >
        {label}
      </p>
      <div
        className={`my-2 block min-h-10 w-full text-sm text-gray-600 p-2 border border-primaryBorder rounded-lg overflow-auto ${
          className ? className : ''
        }`}
      >
        {typeof value === 'string'
          ? value
          : value instanceof Date
          ? value.toLocaleString()
          : value !== null && value !== undefined
          ? value
          : null}
      </div>
    </>
  );
};

export default ViewComplaintField;
