import { useContext, useMemo, useState } from 'react';
import {
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  ColumnDef,
  getSortedRowModel,
  CellContext,
} from '@tanstack/react-table';

import { Complaint } from '../../utils/types/complaint';
import CatalogHeader from '../catalog/CatalogHeader';
import Pagination from '../pagination/Pagination';
import SVGRender from '../svgRender/SVGRender';
import TableComponent from '../table/Table';
import HeaderCell from '../table/HeaderCell';
import Title from '../elements/Title';
import { CatalogDataContext } from '../../contexts/CatalogContextProvider';
import useWizard from '../../hooks/useWizard';
import { ExportButton } from '../exportButton';
import PremiumFeatureModal from '../modal/PremiumFeatureModal';
import { CatalogTableCell } from './CatalogTableCell';
import { CatalogAIIcon } from './CatalogHeaderAIIcon';
import { sortNestedArray } from './sortFunctions';

const CatalogTable = () => {
  const {
    renderBatchTemplateWizard,
    renderUserFormWizard,
    showBatchTemplateWizard,
  } = useWizard();

  const {
    catalogData,
    setCatalogData,
    isBatchFileUpload,
    setIsBatchFileUpload,
  } = useContext(CatalogDataContext);
  const [data, setData] = useState<Complaint[]>(catalogData);
  const [searchQuery, setSearchQuery] = useState('');
  const [showPremiumModal, setShowPremiumModal] = useState(false);

  const allData = useMemo((): Complaint[] => {
    if (searchQuery.trim() === '') return data;
    const lowerCaseQuery = searchQuery.toLowerCase();
    const searchIn = (value: any): boolean =>
      String(value).toLowerCase().includes(lowerCaseQuery);

    return data.filter(
      (row: Complaint) =>
        Object.values(row).some(searchIn) ||
        row.medicalDeviceProblem.value.some(searchIn) ||
        row.clinicalSigns.value.some(searchIn)
    );
  }, [data, searchQuery]);

  const columns = useMemo<ColumnDef<Complaint>[]>(
    () => [
      {
        accessorKey: 'productName',
        size: 51,
        header: () => <HeaderCell value="Product Name" />,
        cell: (props: CellContext<Complaint, any>) => (
          <CatalogTableCell>{props.cell.getValue()}</CatalogTableCell>
        ),
        enableRowSpan: false,
        enableSorting: true,
      },
      {
        accessorKey: 'eventDescription',
        header: () => <HeaderCell value="Event Description" />,
        size: 90,
        cell: (props: CellContext<Complaint, any>) => (
          <CatalogTableCell>{props.cell.getValue()}</CatalogTableCell>
        ),
        enableRowSpan: false,
        enableSorting: true,
      },
      {
        accessorKey: 'medicalDeviceProblem',
        size: 250,
        header: () => (
          <HeaderCell value="Medical Device Problem (IMDRF Annex A)">
            <CatalogAIIcon />
          </HeaderCell>
        ),
        cell: (props: CellContext<Complaint, any>) => (
          <CatalogTableCell>
            {props.cell.getValue().value.join('; ')}
          </CatalogTableCell>
        ),
        sortingFn: (rowA, rowB, _columnId) =>
          sortNestedArray(rowA, rowB, _columnId),
        enableRowSpan: false,
        enableSorting: true,
      },
      {
        accessorKey: 'clinicalSigns',
        size: 250,
        header: () => (
          <HeaderCell value="Clinical Signs and Symptoms or Conditions (IMDRF Annex E)">
            <CatalogAIIcon />
          </HeaderCell>
        ),
        cell: (props: CellContext<Complaint, any>) => (
          <CatalogTableCell>
            {props.cell.getValue().value.join('; ')}
          </CatalogTableCell>
        ),
        sortingFn: (rowA, rowB, _columnId) =>
          sortNestedArray(rowA, rowB, _columnId),
        enableRowSpan: false,
        enableSorting: true,
      },
      {
        accessorKey: 'eventType',
        size: 80,
        header: () => (
          <HeaderCell value="Event Type">
            <CatalogAIIcon />
          </HeaderCell>
        ),
        cell: (props: CellContext<Complaint, any>) => (
          <CatalogTableCell>{props.cell.getValue()}</CatalogTableCell>
        ),
        enableRowSpan: false,
        enableSorting: true,
      },
      {
        accessorKey: 'premiumFeature',
        size: 80,
        header: () => (
          <div className="!w-[100%] lg:!w-[100%] flex flex-1 justify-between items-center text-[#4B5563]">
            Review Status
            <SVGRender svgName="whiteLockIcon" />
          </div>
        ),
        cell: (props: CellContext<Complaint, any>) =>
          props.row.index === 0 ? (
            <div className="flex">
              <button
                onClick={() => setShowPremiumModal(true)}
                className=" text-wrap mx-auto text-center bg-gray-100 border p-1.5 rounded-lg"
              >
                Premium Feature
              </button>
            </div>
          ) : null,
        enableRowSpan: true,
        enableSorting: false,
        rowSpan: `${data.length}`,
        cellClasses: 'bg-gray-200',
      },
    ],
    []
  );

  const table = useReactTable({
    data: allData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta: {
      filterCatalogData: (options: string[]) => {
        const filtered: Complaint[] =
          options.length === 0
            ? [...catalogData]
            : catalogData.filter((item) =>
                options.some((option) =>
                  Object.values(item).some((value) => {
                    if (value === undefined) return false;
                    if (typeof value === 'string')
                      return value.includes(option);
                    if (
                      typeof value === 'object' &&
                      value !== null &&
                      'value' in value
                    ) {
                      return value.value.includes(option);
                    }
                    return false;
                  })
                )
              );
        setData(filtered);
      },

      removeRow: (rowIndex: number) => {
        const setFilterFun = (old: Complaint[]) =>
          old.filter((_row: Complaint, index: number) => index !== rowIndex);
        setData(setFilterFun);
        setCatalogData(setFilterFun);
      },
    },
    defaultColumn: {
      size: 60,
      minSize: 25,
      maxSize: 500,
    },
  });

  return (
    <div className="px-4 pt-4 pb-0">
      {renderUserFormWizard()}
      <div className="flex justify-between items-center p-5">
        <Title text="Catalog" />
        <div className="space-x-5 flex">
          {showBatchTemplateWizard() && (
            <div className="fixed inset-0 bg-black bg-opacity-80 z-40"></div>
          )}
          <div className="relative">
            <div className={`relative ${showBatchTemplateWizard() && 'z-50'}`}>
              <ExportButton buttonType="template" />
            </div>
            {renderBatchTemplateWizard()}
          </div>
          <div>
            <ExportButton buttonType="definitions" />
          </div>
          <div>
            <ExportButton buttonType="sample" />
          </div>
        </div>
      </div>
      <div className="bg-white rounded-md border p-4">
        {isBatchFileUpload && (
          <div className="px-4">
            <div className="w-full flex justify-between items-center h-[56px] border border-green-500 border-l-4 mb-10 p-1.5 rounded-[6px]">
              <div className="flex items-center space-x-4 ml-4">
                <SVGRender svgName="warningIcon" />
                <p className="text-green-800">Batch Upload successful</p>
              </div>
              <SVGRender
                svgName="greenCrossIcon"
                className="mr-2 cursor-pointer"
                onClick={() => setIsBatchFileUpload(false)}
              />
            </div>
          </div>
        )}
        <CatalogHeader setSearchQuery={setSearchQuery} table={table} />
        <div className="-mx-4 -my-2 pb-3">
          {showPremiumModal && (
            <PremiumFeatureModal
              showPremiumFeatureModal={showPremiumModal}
              setShowPremiumFeatureModal={setShowPremiumModal}
            />
          )}
          <div className="inline-block min-w-full align-middle px-7 max-h-[70vh] overflow-scroll bg-white">
            <TableComponent table={table} />
            <div
              className="sticky bg-white"
              style={{ bottom: '0' }}
              data-testid="pagination"
            >
              <Pagination table={table} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CatalogTable;
