import { useState } from 'react';

import { CatalogHeaderProps } from '../../utils/types/complaint';
import FiltersSlideOver from '../filters/FiltersSlideOver';
import ActionButtons from '../filters/ActionButtons';

const CatalogHeader = ({ setSearchQuery, table }: CatalogHeaderProps) => {
  const [isFiltersSlideOpen, setIsFiltersSlideOpen] = useState(false);
  const [checkedFilterOptions, setCheckedFilterOptions] = useState<string[]>(
    []
  );
  const [searchFilterTerm, setSearchFilterTerm] = useState<string>('');

  const meta = table?.options.meta;
  return (
    <div className="mb-5 px-4">
      <FiltersSlideOver
        setOpen={setIsFiltersSlideOpen}
        open={isFiltersSlideOpen}
        meta={meta}
        setCheckedFilterOptions={setCheckedFilterOptions}
        checkedFilterOptions={checkedFilterOptions}
        setSearchFilterTerm={setSearchFilterTerm}
        searchFilterTerm={searchFilterTerm}
      />
      <ActionButtons
        setIsFiltersSlideOpen={setIsFiltersSlideOpen}
        setSearchQuery={setSearchQuery}
        checkedFilterOptions={checkedFilterOptions}
      />
    </div>
  );
};

export default CatalogHeader;
