export const manualFormInitialData = {
  id: '',
  productName: '',
  modelNo: '',
  reportedDate: '',
  region: '',
  eventDescription: '',
};

export const manualFormBreadCrumb = [
  {
    label: 'Catalog',
    link: '/',
  },
  {
    label: 'Manual Entry',
    link: '/manual-entry',
  },
];

export const batchUploadPageBreadCrumbList = [
  {
    label: 'Catalog',
    link: '/',
  },
  {
    label: 'Batch Upload',
    link: '/batch-upload',
  },
];
