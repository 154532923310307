import { SVGRenderProps } from '../../utils/types/complaint';

import { ReactComponent as TemplateIcon } from '../../assets/icons/templateIcon.svg';
import { ReactComponent as TerminologyIcon } from '../../assets/icons/terminologyIcon.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/uploadIcon.svg';
import { ReactComponent as LockIcon } from '../../assets/icons/lockIcon.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/chevronDown.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/chevronUp.svg';
import { ReactComponent as WhiteLockIcon } from '../../assets/icons/white-lockIcon.svg';
import { ReactComponent as AIicon } from '../../assets/icons/aiIcon.svg';
import { ReactComponent as AIiconWhite } from '../../assets/icons/aiIconWhite.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';
import { ReactComponent as ThreeDotsIcon } from '../../assets/icons/menuIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/forwardArrow.svg';
import { ReactComponent as CatalogIcon } from '../../assets/icons/newCatalog.svg';
import { ReactComponent as DoubleArrowLeft } from '../../assets/icons/firstPageIcon.svg';
import { ReactComponent as DoubleArrowRight } from '../../assets/icons/lastPageIcon.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/nextArrow.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/backArrow.svg';
import { ReactComponent as ArrowBack } from '../../assets/icons/arrowBack.svg';
import { ReactComponent as GreenCrossIcon } from '../../assets/icons/greenCross.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warningIcon.svg';
import { ReactComponent as BrowseIcon } from '../../assets/icons/browseIcon.svg';
import { ReactComponent as UploadDropDownIcon } from '../../assets/icons/uploadChevronDown.svg';
import { ReactComponent as SortIcon } from '../../assets/icons/sort.svg';
import { ReactComponent as DownloadFileIcon } from '../../assets/icons/downloadFile.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/arrowUp.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/DownArrow.svg';
import { ReactComponent as ArrowIconGrey } from '../../assets/icons/arrowIconGrey.svg';
import { ReactComponent as SortDown } from '../../assets/icons/sort-down.svg';
import { ReactComponent as SortUp } from '../../assets/icons/sort-up.svg';

// TODO: Refactor to dynamically set color

const SVGRender = ({ svgName, className = '', onClick }: SVGRenderProps) => {
  const svgComponents: {
    [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  } = {
    doubleArrowLeft: DoubleArrowLeft,
    downloadFile: DownloadFileIcon,
    doubleArrowRight: DoubleArrowRight,
    arrowRight: ArrowRight,

    arrowLeft: ArrowLeft,
    templateIcon: TemplateIcon,
    crossIcon: CrossIcon,
    terminologyIcon: TerminologyIcon,
    uploadIcon: UploadIcon,
    lockIcon: LockIcon,
    arrowIcon: ArrowIcon,
    arrowIconGrey: ArrowIconGrey,
    ArrowBack,
    filterIcon: FilterIcon,
    arrowDown: ArrowDown,
    arrowUp: ArrowUp,
    whiteLockIcon: WhiteLockIcon,
    aIicon: AIicon,
    aIiconWhite: AIiconWhite,
    searchIcon: SearchIcon,
    threeDotsIcon: ThreeDotsIcon,
    catalogIcon: CatalogIcon,
    greenCrossIcon: GreenCrossIcon,
    warningIcon: WarningIcon,
    browseIcon: BrowseIcon,
    uploadDropDownIcon: UploadDropDownIcon,
    sortIcon: SortIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    sortDown: SortDown,
    sortUp: SortUp,
  };

  const SVGComponent = svgComponents[svgName];
  if (!SVGComponent) return null;

  return <SVGComponent className={className} onClick={onClick} />;
};

export default SVGRender;
