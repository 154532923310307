import { useState, ChangeEvent, useContext, useMemo } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { toast } from 'react-hot-toast';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

import {
  ComplaintFormDataProps,
  Predictions,
} from '../../utils/types/complaint';
import { addComplaints } from '../../utils/apiCall/complaints';
import { CatalogDataContext } from '../../contexts/CatalogContextProvider';
import { manualFormInitialData } from '../../utils/constant';
import FormInput from '../elements/FormInput';
import ManualEntryFooter from './ManualEntryFooter';
import ManualEntryFormHeader from './ManualEntryFormHeader';
import { useNavigate } from 'react-router-dom';
import { dataGenerator } from '../../utils/helperFunctions';
import { secrets } from '../../secrets';

const DEFAULT_LIMIT = 100;

const ManualEntryForm = () => {
  const {
    catalogData,
    setCatalogData,
    manualComplaintsLength,
    setManualComplaintLength,
  } = useContext(CatalogDataContext);
  const [formsData, setFormsData] = useState<ComplaintFormDataProps[]>([
    { ...manualFormInitialData, id: Date.now() },
  ]);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState([false]);

  const onChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    setFormsData((prevFormsData) => {
      const newData = [...prevFormsData];
      newData[index] = {
        ...newData[index],
        [name]: value,
      };
      return newData;
    });

    const newErrors = [...formErrors];
    newErrors[index] = false;
    setFormErrors(newErrors);
  };

  const handleSubmit = async () => {
    let anyEmptyEventDescription = false;
    const newErrors = formsData.map((form) => {
      const isEmptyEventDescription = form.eventDescription?.trim() === '';
      if (isEmptyEventDescription) anyEmptyEventDescription = true;
      return isEmptyEventDescription;
    });
    if (anyEmptyEventDescription) {
      setFormErrors(newErrors);
      return;
    }

    try {
      setIsLoading(true);
      const { data: response } = await addComplaints(formsData);
      setManualComplaintLength((prev) => prev + formsData.length);
      const predictions: Predictions[] = JSON.parse(
        response.data.AddComplaint.output as string
      );
      const updateCatalogData = dataGenerator(formsData, predictions);
      setCatalogData([...catalogData, ...updateCatalogData]);
      setFormsData([{ ...manualFormInitialData, id: Date.now() }]);
      toast.success('Form submitted successfully');
      navigate('/');
    } catch (error: any) {
      toast.error(error.message as string);
    } finally {
      setIsLoading(false);
    }
  };

  const addForm = () => {
    setFormsData([...formsData, { ...manualFormInitialData, id: Date.now() }]);
    setFormErrors([...formErrors, false]);
  };

  const removeForm = (index: number) => {
    setFormsData((prevFormsData) =>
      prevFormsData.filter((_, formIndex) => formIndex !== index)
    );

    setFormErrors((prevErrors) =>
      prevErrors.filter((_, formIndex) => formIndex !== index)
    );
  };

  // TODO: Move into context
  const isTotalLimitReachedButNotManual = useMemo(
    () =>
      catalogData.length + formsData.length >=
        Number(secrets.complaintsLimit || DEFAULT_LIMIT) ||
      formsData.length + manualComplaintsLength >=
        Number(secrets.manualComplaintsLimit || DEFAULT_LIMIT),
    [catalogData, formsData, manualComplaintsLength]
  );

  const complaintLimitReached = useMemo(
    () =>
      formsData.length + manualComplaintsLength >=
        Number(secrets.manualComplaintsLimit || DEFAULT_LIMIT) ||
      formsData.length + catalogData.length >=
        Number(secrets.complaintsLimit || DEFAULT_LIMIT),
    [formsData.length, manualComplaintsLength, catalogData]
  );

  if (isTotalLimitReachedButNotManual) {
    toast.error(
      `Maximum of ${secrets.complaintsLimit} complaints allowed per SmartComplaints demo version session.`
    );
  }
  return (
    <>
      <ManualEntryFormHeader
        isLoading={isLoading}
        formErrors={formErrors}
        handleSubmit={() => void handleSubmit()}
        complaintLimitReached={complaintLimitReached}
      />
      <div className="border px-2 py-10 rounded-md mx-7 bg-white">
        <div className="space-y-5">
          {formsData.map(
            (
              { productName, modelNo, reportedDate, region, eventDescription },
              index
            ) => (
              <div className="w-full border-b py-2" key={index}>
                <div className="mx-auto">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg px-2 text-left text-sm font-medium">
                          <span className="font-bold text-primaryColor text-lg pl-2">
                            Complaint {index + 1}
                          </span>
                          <div className="flex items-center space-x-3">
                            {index === 0 ? null : (
                              <p
                                className="text-primaryColor text-sm underline"
                                onClick={() => removeForm(index)}
                              >
                                Remove
                              </p>
                            )}
                            <ChevronUpIcon
                              className={`${
                                !open
                                  ? 'rotate-180 transform ease-in-out duration-300'
                                  : 'ease-in-out duration-300'
                              } h-5 w-5`}
                            />
                          </div>
                        </Disclosure.Button>
                        <Transition
                          show={!open}
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel className="px-4 pb-2 text-sm text-gray-500">
                            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                              <div className="sm:col-span-3 sm:col-start-1">
                                <FormInput
                                  label="Product Name"
                                  name="productName"
                                  type="text"
                                  value={productName}
                                  onChange={(
                                    event: ChangeEvent<HTMLInputElement>
                                  ) => onChangeHandler(event, index)}
                                />
                              </div>
                              <div className="sm:col-span-1">
                                <FormInput
                                  label="Model No."
                                  name="modelNo"
                                  type="text"
                                  value={modelNo}
                                  onChange={(
                                    event: ChangeEvent<HTMLInputElement>
                                  ) => onChangeHandler(event, index)}
                                />
                              </div>
                              <div className="sm:col-span-1">
                                <FormInput
                                  label="Reported Date"
                                  name="reportedDate"
                                  type="date"
                                  value={reportedDate}
                                  additionalClasses="cursor-pointer"
                                  onChange={(
                                    event: ChangeEvent<HTMLInputElement>
                                  ) => onChangeHandler(event, index)}
                                />
                              </div>
                              <div className="sm:col-span-1">
                                <FormInput
                                  label="Country / Region"
                                  name="region"
                                  type="text"
                                  value={region}
                                  onChange={(
                                    event: ChangeEvent<HTMLInputElement>
                                  ) => onChangeHandler(event, index)}
                                />
                              </div>
                              <div className="col-span-full">
                                <FormInput
                                  label="Event Description *"
                                  name="eventDescription"
                                  type="text"
                                  fildType="textarea"
                                  additionalProps={{ rows: 7 }}
                                  value={eventDescription}
                                  onChange={(
                                    event: ChangeEvent<HTMLInputElement>
                                  ) => onChangeHandler(event, index)}
                                />
                                {formErrors[index] && (
                                  <div className="text-red-500 font-bold pl-2 col-span-full">
                                    event description should not be left blank.
                                  </div>
                                )}
                              </div>
                            </div>
                          </Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                </div>
              </div>
            )
          )}

          <ManualEntryFooter
            addForm={addForm}
            complaintLimitReached={complaintLimitReached}
          />
        </div>
      </div>
    </>
  );
};

export default ManualEntryForm;
