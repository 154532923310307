import { Complaint } from '../../utils/types/complaint';
import AIDataField from '../elements/AIDataField';
import SVGRender from '../svgRender/SVGRender';
import Tooltip from '../tooltip';

const AIData = ({ complaint }: { complaint: Complaint }) => {
  return (
    <>
      <div className="w-full flex items-center  my-8 space-x-2">
        <h3 className="text-lg text-[#177BA6] font-bold">
          IMDRF Adverse Event Reporting
        </h3>
        <Tooltip text="Auto-generated Classification">
          <SVGRender svgName="aIicon" className="fill-[#177BA6]" />
        </Tooltip>
      </div>
      <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-12">
        <div className="lg:col-span-10 sm:col-start-1">
          <div className="flex flex-col lg:flex-row items-start space-x-0 lg:space-x-8">
            <div className="w-full">
              <h4 className="text-[#177BA6] text-sm font-semibold tracking-wide">
                Medical Device Problem & Code (IMDRF Annex A)
              </h4>
              <div className="flex flex-col">
                {complaint.medicalDeviceProblem.value.map(
                  (item: string, index: number) => (
                    <AIDataField
                      key={`${item}_${index}`}
                      value={item}
                      code={complaint.medicalDeviceProblem.code[index]}
                    />
                  )
                )}
              </div>
            </div>
            <div className="w-full">
              <h4 className="text-[#177BA6] text-sm font-semibold tracking-wide">
                Clinical Signs and Symptoms or Conditions & Code (IMDRF Annex E)
              </h4>
              <div className="flex flex-col">
                {complaint.clinicalSigns.value.map(
                  (item: string, index: number) => (
                    <AIDataField
                      key={`${item}_${index}`}
                      value={item}
                      code={complaint.clinicalSigns.code[index]}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-2">
          <h4 className="text-[#177BA6] text-sm font-semibold tracking-wide">
            Event Type
          </h4>
          <div className="relative flex">
            <div
              className={`my-2 py-2 pl-1 pr-4 block min-h-10 w-full text-sm text-gray-600 border border-primaryBorder rounded-lg `}
            >
              {complaint.eventType || ''}
            </div>
            <button type="submit" className="absolute right-2 top-5">
              <span className="group">
                <Tooltip
                  text="Auto-generated Classification"
                  position="left"
                  verticalPercOffset={-300}
                  horizontalPercOffset={-528}
                >
                  <SVGRender svgName="aIicon" />
                </Tooltip>
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AIData;
