import { MenuItemProps, MenuItemsListProps } from '../../utils/types/elements';
import MenuItem from './MenuItem';

const MenuItemsList = ({ menuItems }: MenuItemsListProps) => {
  return (
    <div className="py-1 border border-primaryBorder rounded-md">
      {menuItems.map(
        ({ title, onClick, className }: MenuItemProps, index: number) => (
          <MenuItem
            key={index}
            text={title}
            onClick={onClick}
            className={className}
          />
        )
      )}
    </div>
  );
};

export default MenuItemsList;
