import { useState } from 'react';
import Modal from './Modal';
import SVGRender from '../svgRender/SVGRender';
import { VerificationModalProps } from '../../utils/types/modal';
import Button from '../elements/Button';
import { useMutation } from '@tanstack/react-query';
import { addInterestedUser } from '../../utils/apiCall/users';
import { toast } from 'react-hot-toast';

const PremiumFeatureModal = ({
  showPremiumFeatureModal,
  setShowPremiumFeatureModal,
}: VerificationModalProps) => {
  const [buttonClicked, setButtonClicked] = useState<boolean>(false);

  const showInterest = useMutation<any, Error, string, unknown>({
    mutationFn: (id) => addInterestedUser(id),
    onSuccess: () => {
      setTimeout(() => {
        setButtonClicked(false);
        setShowPremiumFeatureModal(false);
      }, 2000);
    },
    onError: () => {
      toast.error('There was an error, please try again');
    },
  });
  const handleButtonClick = () => {
    setButtonClicked(true);
    const user = localStorage.getItem('user');
    const id = user ? (JSON.parse(user).id as string) : '';
    showInterest.mutate(id, {});
  };
  return (
    <Modal
      title="This is a Premium Feature!"
      isOpen={showPremiumFeatureModal}
      setIsOpen={setShowPremiumFeatureModal}
    >
      <div className="mt-5">
        <p className="text-md text-globalTokensNeutral">
          {`If you’re interested in a Custom version of SmartComplaints™, let's chat!`}
        </p>
        <Button
          Disabled={buttonClicked}
          buttonLabel={
            <>
              I&apos;m Interested!
              <SVGRender
                svgName={buttonClicked ? 'arrowIconGrey' : 'arrowIcon'}
                className="ml-2 mt-0.5"
              />
            </>
          }
          additionalClasses={
            !buttonClicked ? 'bg-primaryColor text-white mt-5' : 'mt-5'
          }
          onClick={handleButtonClick}
        />

        {buttonClicked && (
          <div className="text-base text-globalTokensNeutral mt-[24px] font-bold">
            Thank you, we&apos;ll reach out soon!
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PremiumFeatureModal;
