import { FC, ReactNode } from 'react';
import SVGRender from '../svgRender/SVGRender';
import { secrets } from '../../secrets';

type ExportButtonProps = {
  buttonType: 'definitions' | 'template' | 'sample';
};

export const ExportButton: FC<ExportButtonProps> = ({ buttonType }) => {
  let link = '';
  let buttonText: ReactNode = null;
  let downloadText = '';

  const definitionsBtnText = (
    <div className="flex items-center space-x-2">
      <SVGRender svgName="downloadFile" />
      <p>Definitions</p>
    </div>
  );

  const templateBtnText = (
    <div className="flex items-center space-x-2 ">
      <SVGRender svgName="downloadFile" />
      <p>Batch Template</p>
    </div>
  );

  const sampleBtnText = (
    <div className="flex items-center space-x-2 ">
      <SVGRender svgName="downloadFile" />
      <p>Sample Batch Data</p>
    </div>
  );

  switch (buttonType) {
    case 'template':
      link = secrets.batchTemplateFileLink as string;
      buttonText = templateBtnText;
      downloadText = 'SmartComplaints_Batch_Template.xlsx';
      break;
    case 'definitions':
      buttonText = definitionsBtnText;
      downloadText = 'SmartComplaints_Terminology_Library.xlsx';
      link = secrets.terminologyLibraryFileLink as string;
      break;
    case 'sample':
      buttonText = sampleBtnText;
      downloadText = 'SmartComplaints_Terminology_Library.xlsx';
      link = secrets.sampleBatchDataFileLink as string;
      break;
    default:
      link = '';
      downloadText = '';
      buttonText = null;
      break;
  }

  return (
    <a
      href={link}
      download={downloadText}
      target="_self"
      className="bg-primaryColor text-white inline-flex justify-center text-sm md:text-base py-2 px-4 rounded-[4px] border font-semibold"
    >
      {buttonText}
    </a>
  );
};
