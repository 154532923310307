import { ChangeEventHandler } from 'react';
import SVGRender from '../svgRender/SVGRender';

const SearchField = ({
  className = '',
  placeholder = '',
  onChange = () => {},
  value,
}: {
  className?: string;
  placeholder?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value?: string;
}): JSX.Element => {
  return (
    <div className={`relative w-full ${className}`}>
      <input
        type="text"
        name="q"
        className="block w-full min-h-[auto] rounded-[4px] border border-primaryBorder pl-10 py-[0.45rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:border-blue-500 focus:bg-white text-gray-500 placeholder:text-gray-400"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      <button type="submit" className="absolute left-0 top-2.5 lg:top-2.5 ml-3">
        <SVGRender svgName="searchIcon" />
      </button>
    </div>
  );
};

export default SearchField;
