import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CatalogDataContext } from '../../contexts/CatalogContextProvider';
import ViewComplaintData from '../../components/complaint/ViewComplaintData';
import PageHeader from '../../components/pageHeader/PageHeader';
import { ExportButton } from '../../components/exportButton';

const ViewComplaint = () => {
  const { catalogData } = useContext(CatalogDataContext);
  const { id } = useParams();

  const { data, nextComplaintId, prevComplaintId } = useMemo(() => {
    const index = catalogData.findIndex(
      (complaint) => complaint.id === Number(id)
    );
    return {
      data: catalogData[index] || null,
      nextComplaintId: catalogData[index + 1]?.id || undefined,
      prevComplaintId: catalogData[index - 1]?.id || undefined,
    };
  }, [catalogData, id]);

  const breadCrumbList = [
    {
      label: 'Catalog',
      link: '/',
    },
    {
      label: 'View Complaint',
      link: `/${id}/view-complaint`,
    },
  ];
  return (
    <>
      <PageHeader title="View Complaint" breadCrumbList={breadCrumbList}>
        <ExportButton buttonType="definitions" />
      </PageHeader>
      <div className="px-7">
        <ViewComplaintData
          complaint={data}
          nextComplaintId={nextComplaintId}
          prevComplaintId={prevComplaintId}
        />
      </div>
    </>
  );
};

export default ViewComplaint;
