import { Link } from 'react-router-dom';
import { manualFormBreadCrumb } from '../../utils/constant';
import { ManualEntryFormHeaderProps } from '../../utils/types/ManualEntryForm';
import PageHeader from '../pageHeader/PageHeader';
import Button from '../elements/Button';

const ManualEntryFormHeader = ({
  isLoading,
  formErrors,
  handleSubmit,
  complaintLimitReached,
}: ManualEntryFormHeaderProps) => {
  const buttonDisabled =
    formErrors.some((err: boolean) => err) || complaintLimitReached;
  return (
    <PageHeader title="Manual Entry" breadCrumbList={manualFormBreadCrumb}>
      <div className="flex items-center justify-center space-x-4">
        <Button
          Disabled={buttonDisabled}
          buttonLabel={`${isLoading ? 'Uploading...' : 'Upload'}`}
          onClick={isLoading ? () => {} : handleSubmit}
        />

        <Link
          to="/"
          className="inline-flex justify-center text-sm md:text-base py-2 px-4 rounded-[4px] border font-semibold text-primaryColor border-primaryBorder"
        >
          Cancel
        </Link>
      </div>
    </PageHeader>
  );
};

export default ManualEntryFormHeader;
