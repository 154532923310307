import { Menu } from '@headlessui/react';

interface MenuItemProps {
  text: string | JSX.Element;
  onClick?: () => void;
  className?: string;
}

const MenuItem: React.FC<MenuItemProps> = ({
  text,
  onClick,
  className = '',
}: MenuItemProps) => (
  <span onClick={onClick}>
    <Menu.Item>
      <button
        className={`${className}  w-[100%] block text-sm hover:bg-gray-200 text-left`}
      >
        {text}
      </button>
    </Menu.Item>
  </span>
);

export default MenuItem;
