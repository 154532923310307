import { FC } from 'react';
import Tooltip from '../tooltip';
import SVGRender from '../svgRender/SVGRender';

export const CatalogAIIcon: FC = () => {
  return (
    <Tooltip
      text="Auto-generated Classification"
      verticalPercOffset={160}
      position="bottom"
    >
      <SVGRender svgName="aIiconWhite" />
    </Tooltip>
  );
};
