import { Row } from '@tanstack/react-table';
import { Complaint } from '../../utils/types/complaint';

export const sortNestedArray = (
  rowA: Row<Complaint>,
  rowB: Row<Complaint>,
  columnId: string
) =>
  rowA.original[columnId].value
    .join('; ')
    .localeCompare(rowB.original[columnId].value.join('; '));
