import {
  Complaint,
  ComplaintFormDataProps,
  Predictions,
} from './types/complaint';

const formatPrediction = (
  prediction: Predictions,
  type: 'medical_device_problem' | 'clinical_signs'
) => {
  const values: string[] = [];
  const codes: string[] = [];

  for (const item of prediction[type].final) {
    values.push(item.value);
    codes.push(item.code);
  }

  return {
    value: values,
    code: codes,
  };
};
export const dataGenerator = (
  formsData: ComplaintFormDataProps[],
  predictions: Predictions[]
) => {
  return formsData.map((form: any, index: number) => {
    const prediction = predictions[index];
    return {
      ...form,
      medicalDeviceProblem: formatPrediction(
        prediction,
        'medical_device_problem'
      ),
      clinicalSigns: formatPrediction(prediction, 'clinical_signs'),
      eventType: predictions[index].prediction_event,
    };
  });
};
export const transformDataToFilterOptions = (
  data: Complaint[],
  fields: { title: string; key: string }[]
) => {
  return fields.map((field: any) => {
    const options = Array.from(
      new Set(
        data
          .map((item: any) => {
            if (
              field.key === 'medicalDeviceProblem' ||
              field.key === 'clinicalSigns'
            ) {
              return item[field.key].value;
            }
            return item[field.key];
          })
          .flat()
          .filter((value: string) => value !== '')
          .sort((a: string, b: string) =>
            a.localeCompare(b, 'en', { sensitivity: 'base' })
          )
      )
    );

    return {
      title: field.title,
      options,
    };
  });
};
