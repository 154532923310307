import { useContext, useEffect, useMemo, useState } from 'react';
import { Dialog } from '@headlessui/react';

import {
  FilterOption,
  FiltersSlideOverProps,
} from '../../utils/types/elements';
import { CatalogDataContext } from '../../contexts/CatalogContextProvider';
import { transformDataToFilterOptions } from '../../utils/helperFunctions';
import SearchField from '../elements/SearchField';
import FilterDisclosure from '../disclosure/Disclosure';
import Button from '../elements/Button';
import SlideOver from '../slideOver/SlideOver';

const FiltersSlideOver = ({
  open,
  setOpen,
  meta,
  setCheckedFilterOptions,
  checkedFilterOptions,
  searchFilterTerm,
  setSearchFilterTerm,
}: FiltersSlideOverProps) => {
  const { catalogData } = useContext(CatalogDataContext);
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);

  const fields = [
    { title: 'Product Name', key: 'productName' },
    { title: 'Medical Device Problem', key: 'medicalDeviceProblem' },
    {
      title: 'Clinical Signs and Symptoms or Conditions',
      key: 'clinicalSigns',
    },
    { title: 'Event Type', key: 'eventType' },
  ];

  const filtersList = useMemo(
    () => transformDataToFilterOptions(catalogData, fields),
    [catalogData, fields]
  );

  const closeModal = () => {
    setOpen(false);
    setFilterOptions(filtersList);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const updatedOptions = e.target.checked
      ? [...checkedFilterOptions, value]
      : checkedFilterOptions.filter((option: string) => option !== value);
    setCheckedFilterOptions(updatedOptions);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchFilterTerm(value);
  };

  const filteredOptions = useMemo(() => {
    const lowerCaseTerm = searchFilterTerm.toLowerCase();
    return filterOptions.map(({ title, options }: FilterOption) => ({
      title,
      options: options.filter((value: string) => {
        return value.toLowerCase().includes(lowerCaseTerm);
      }),
    }));
  }, [filterOptions, searchFilterTerm]);

  const filterDataHandler = (options: string[]) => {
    meta?.filterCatalogData(options);
    closeModal();
  };

  useEffect(() => {
    setFilterOptions(filtersList);
  }, []);

  return (
    <SlideOver open={open} setOpen={setOpen}>
      <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
        <div className="h-0 flex-1 overflow-y-auto">
          <div className="bg-secondaryColor px-4 py-6 sm:px-6">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-base font-semibold leading-6 text-white">
                Filter Complaints
              </Dialog.Title>
            </div>
          </div>
          <div className="flex flex-1 flex-col justify-between">
            <div className="divide-y divide-gray-200 px-4 sm:px-6">
              <div className="space-y-6 pb-5 pt-6">
                <SearchField
                  onChange={handleSearchChange}
                  placeholder="Search"
                  value={searchFilterTerm}
                />
                <div className="space-y-2">
                  {filteredOptions.map((filter, index) => (
                    <FilterDisclosure
                      key={index}
                      filter={filter}
                      handleCheckboxChange={handleCheckboxChange}
                      checkedFilterOptions={checkedFilterOptions}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-shrink-0 justify-between px-4 py-4">
          <Button
            buttonLabel="Clear Filters"
            onClick={() => {
              setCheckedFilterOptions([]);
              filterDataHandler([]);
            }}
            additionalClasses="text-primaryColor border-primaryBorder"
          />

          <div className="space-x-2">
            <Button
              buttonLabel="Filter"
              onClick={() => filterDataHandler(checkedFilterOptions)}
              additionalClasses={`${
                checkedFilterOptions.length > 0
                  ? 'bg-primaryColor text-white'
                  : 'bg-gray-200 text-gray-400 cursor-not-allowed border-primaryBorder'
              }`}
              additionalProps={{ disabled: checkedFilterOptions.length === 0 }}
            />
            <Button
              buttonLabel="Close"
              onClick={closeModal}
              additionalClasses="text-primaryColor border-primaryBorder"
            />
          </div>
        </div>
      </div>
    </SlideOver>
  );
};

export default FiltersSlideOver;
