import { ReactNode, createContext, useState } from 'react';
import { Complaint } from '../utils/types/complaint';

export const CatalogDataContext = createContext<{
  catalogData: Complaint[];
  setCatalogData: React.Dispatch<React.SetStateAction<Complaint[]>>;
  isBatchFileUpload: boolean;
  setIsBatchFileUpload: React.Dispatch<React.SetStateAction<boolean>>;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  currentWizard: number;
  setCurrentWizard: React.Dispatch<React.SetStateAction<number>>;
  welcomeWizard: boolean;
  setWelcomeWizard: React.Dispatch<React.SetStateAction<boolean>>;
  handleNextWizard: () => void;
  welcomeSlidesCount: number;
  manualComplaintsLength: number;
  setManualComplaintLength: React.Dispatch<React.SetStateAction<number>>;
}>({
  catalogData: [],
  setCatalogData: () => {},
  isBatchFileUpload: false,
  setIsBatchFileUpload: () => {},
  pageSize: 10,
  setPageSize: () => {},
  currentWizard: 0,
  setCurrentWizard: () => {},
  welcomeWizard: true,
  setWelcomeWizard: () => {},
  handleNextWizard: () => {},
  welcomeSlidesCount: 5,
  manualComplaintsLength: 0,
  setManualComplaintLength: () => {},
});
const CatalogContext = ({ children }: { children: ReactNode }) => {
  const [catalogData, setCatalogData] = useState<Complaint[]>([]);
  const [isBatchFileUpload, setIsBatchFileUpload] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentWizard, setCurrentWizard] = useState(1);
  const [welcomeWizard, setWelcomeWizard] = useState<boolean>(false);
  const welcomeSlidesCount = 5;

  const handleNextWizard = () => {
    if (currentWizard === welcomeSlidesCount) setWelcomeWizard(false);
    setCurrentWizard((prevStep: number) => prevStep + 1);
  };
  const [manualComplaintsLength, setManualComplaintLength] =
    useState<number>(0);

  return (
    <CatalogDataContext.Provider
      value={{
        catalogData,
        setCatalogData,
        isBatchFileUpload,
        setIsBatchFileUpload,
        pageSize,
        setPageSize,
        currentWizard,
        setCurrentWizard,
        welcomeWizard,
        setWelcomeWizard,
        handleNextWizard,
        welcomeSlidesCount,
        manualComplaintsLength,
        setManualComplaintLength,
      }}
    >
      {children}
    </CatalogDataContext.Provider>
  );
};

export default CatalogContext;
