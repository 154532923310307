import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/sidebar/Sidebar';

const Layout = () => {
  return (
    <>
      <Sidebar />
      <div className="pt-[50px] sm:pt-0 sm:ml-20 mb-10">
        <Outlet />
      </div>
    </>
  );
};

export default memo(Layout);
