import { useContext } from 'react';
import SVGRender from '../svgRender/SVGRender';
import { CatalogDataContext } from '../../contexts/CatalogContextProvider';

const UploadWizard = ({
  title,
  description,
}: {
  title: string;
  description: string | JSX.Element;
}) => {
  const { currentWizard, handleNextWizard, welcomeSlidesCount } =
    useContext(CatalogDataContext);

  return (
    <div className="absolute left-1/2 transform -translate-x-1/2 top-full mt-2 z-50 w-[350px]">
      <div className=" flex flex-col justify-center align-middle items-center 0">
        <SVGRender svgName="ArrowUpIcon" className="" />
        <div className="bg-white p-4 -mt-1  text-[#4B5563] rounded-lg shadow-lg max-w-md">
          <p className=" mb-4">
            <b className="text-[#4B5563]">{title}</b>
            <br />
            {description}
          </p>
          <div className="flex justify-end ">
            <button
              onClick={handleNextWizard}
              className="px-4 py-2 text-sm  rounded-md shadow-sm bg-[#AC5C06] text-[white] focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              {currentWizard}/{welcomeSlidesCount} Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadWizard;
