import React from 'react';
import Button from '../elements/Button';
import SVGRender from '../svgRender/SVGRender';
import { useNavigate } from 'react-router-dom';

interface ComplaintNavigationProps {
  prevComplaintId?: number;
  nextComplaintId?: number;
}

const ComplaintNavigation: React.FC<ComplaintNavigationProps> = ({
  prevComplaintId,
  nextComplaintId,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between">
      {prevComplaintId && (
        <Button
          buttonType="secondary"
          buttonLabel={
            <>
              <SVGRender svgName="ArrowBack" className="mr-2" />
              {'Previous Complaint'}
            </>
          }
          onClick={() => navigate(`/${prevComplaintId}/view-complaint`)}
          additionalClasses="items-center rounded-md complaint-prev-navigation shadow-sm text-base"
        />
      )}

      {nextComplaintId && (
        <Button
          buttonLabel="Next Complaint"
          additionalClasses={`items-center rounded-md complaint-next-navigation shadow-sm text-base ${
            !prevComplaintId ? 'ml-auto' : ''
          }`}
          onClick={() => navigate(`/${nextComplaintId}/view-complaint`)}
        />
      )}
    </div>
  );
};

export default ComplaintNavigation;
